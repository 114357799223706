<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="per_page"
      :current-page="current_page"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      show-empty
    >
      <!-- CUSTOM SHOW EMPTY DATA -->
      <template #empty="scope">
        <center>
          <h6>{{ scope.emptyText }}</h6>
        </center>
      </template>

      <!-- CUSTOM SHOW EMPTY FILTERED -->
      <template #emptyfiltered="scope">
        <center>
          <h6>{{ scope.emptyFilteredText }}</h6>
        </center>
      </template>

      <!-- CUSTOM CELL NO -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- CUSTOM CELL PLATFORM -->
      <template #cell(platform)="data">
        <!-- MEDIA AVATAR -->
        <b-avatar variant="light-primary">
          <!-- TWITTER -->
          <i
            v-if="data.value == 'twitter'"
            class="bi bi-twitter twitter-color fa-lg"
          ></i>

          <!-- FACEBOOK -->
          <i
            v-if="data.value == 'facebook'"
            class="bi bi-facebook facebook-color fa-lg"
          ></i>

          <!-- INSTAGRAM -->
          <i
            v-if="data.value == 'instagram'"
            class="bi bi-instagram instagram-color fa-lg"
          ></i>

          <!-- YOUTUBE -->
          <i
            v-if="data.value == 'youtube'"
            class="bi bi-youtube youtube-color fa-lg"
          ></i>

          <!-- LINKEDIN -->
          <i
            v-if="data.value == 'linkedin'"
            class="bi bi-linkedin linkedin-color fa-lg"
          ></i>

          <!-- FORUM -->
          <i
            v-if="data.value == 'forum'"
            class="fa-solid fa-comments forum-color fa-lg"
          ></i>

          <!-- BLOG -->
          <i
            v-if="data.value == 'blog'"
            class="bi bi-rss-fill blog-color fa-lg"
          ></i>

          <!-- TIKTOK -->
          <i
            v-if="data.value == 'tiktok'"
            class="bi bi-tiktok tiktok-color fa-lg"
          ></i>

          <!-- MEDIA ONLINE -->
          <i
            v-if="data.value == 'media online'"
            class="bi bi-globe media-online-color"
          >
          </i>

          <!-- MEDIA CETAK -->
          <i
            v-else-if="data.value == 'media cetak'"
            class="bi bi-newspaper media-cetak-color"
          >
          </i>

          <!-- MEDIA ELEKTRONIK -->
          <i
            v-else-if="data.value == 'media elektronik'"
            class="bi bi-display media-elektronik-color"
          >
          </i>
        </b-avatar>
      </template>

      <!-- CUSTOM CELL NAME -->
      <template #cell(name)="data">
        <!-- USER AVATAR -->
        <b-avatar :src="data.item.avatar" class="mr-1" />

        <!-- USER NAME -->
        <b class="mr-1">{{ data.item.name }}</b>

        <!-- PERS ACCOUNT -->
        <b-badge
          v-if="data.item.pers === true"
          pill
          variant="warning"
          class="mr-1"
        >
          Pers
        </b-badge>

        <!-- NOTIFICATION IS ACTIVE -->
        <b-badge v-if="data.item.notification === true" pill variant="dark">
          <i class="bi bi-bell"></i>
        </b-badge>
      </template>

      <!-- CUSTOM CELL ACTION -->
      <template #cell(action)="value">
        <span>
          <!-- SHOW ICON -->
          <b-button
            variant="outline-success"
            size="sm"
            class="btn-icon mr-50"
            v-b-tooltip.hover.v-dark="'Lihat Detail'"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <!-- LINK ICON -->
          <b-button
            variant="outline-warning"
            size="sm"
            class="btn-icon"
            v-b-tooltip.hover.v-dark="'Kunjungi'"
          >
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>

          <!-- DROPDOWN BUTTON -->
          <span style="margin-right: -20px">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              size="sm"
              no-caret
            >
              <!-- CUSTOM BUTTON CONTENT -->
              <template v-slot:button-content>
                <b-button
                  variant="outline-info"
                  size="sm"
                  class="btn-icon"
                  v-b-tooltip.hover.v-dark="'Selengkapnya'"
                  style="margin-left: -8.5px"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="14"
                    class="text-info"
                  />
                </b-button>
              </template>

              <!-- VIEW POSTINGAN -->
              <b-dropdown-item>
                <i class="bi bi-eye text-dark mr-1"></i>
                <span>Lihat Detail</span>
              </b-dropdown-item>

              <!-- MAKE PERS BUTTON -->
              <b-dropdown-item
                v-if="value.item.pers !== true"
                v-on:click="confirmPers(value.item.id)"
              >
                <i class="bi bi-pen text-dark mr-1"></i>
                <span>Jadikan Pers</span>
              </b-dropdown-item>

              <!-- MAKE NON PERS BUTTON -->
              <b-dropdown-item
                v-if="value.item.pers === true"
                v-on:click="confirmNonPers(value.item.id)"
              >
                <i class="bi bi-pen text-dark mr-1"></i>
                <span>Jadikan Non Pers</span>
              </b-dropdown-item>

              <!-- MAKE NOTIFICATION IS ACTIVE BUTTON -->
              <b-dropdown-item
                v-if="value.item.notification !== true"
                v-on:click="turnOnNotification(value.item.id)"
              >
                <i class="bi bi-bell-fill text-dark mr-1"></i>
                <span>Hidupkan Pemberitahuan</span>
              </b-dropdown-item>

              <!-- MAKE NOTIFICATION IS NOT ACTIVE BUTTON -->
              <b-dropdown-item
                v-if="value.item.notification === true"
                v-on:click="turnOffNotification(value.item.id)"
              >
                <i class="bi bi-bell-slash-fill text-dark mr-1"></i>
                <span>Matikan Pemberitahuan</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
    </b-table>

    <!-- ROW INFORMATION AND SELECT ROW -->
    <div class="d-flex mt-2">
      <div class="d-flex align-items-center mr-auto ml-2">
        <div>Showing {{ current_page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select v-model="per_page" :options="page_options" />
        </div>
        <div>
          of
          {{ data.items.length }} Entries
        </div>
      </div>

      <!-- PAGINATION -->
      <div class="ml-auto mt-1 mr-2">
        <b-pagination
          v-model="current_page"
          :total-rows="data.items.length"
          :per-page="per_page"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "sourceTable",
  components: {
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    VBTooltip,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      per_page: this.data.row,
      page_options: [3, 5, 10],
      current_page: 1,
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    confirmPers(id) {
      let idrows = id;
      this.userData = this.data.items.find((el) => el.id == idrows);
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Anda akan menjadikan akun ini sebagai <strong>Pers</strong></div>",
        icon: "warning",
        confirmButtonText: "Ya",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Batal",
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.userData.pers = true;
          this.makeToast("success", "Akun Telah Ditandai Sebagai Pers", 5000);
        }
      });
    },
    confirmNonPers(id) {
      let idrows = id;
      this.userData = this.data.items.find((el) => el.id == idrows);
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Anda akan menjadikan akun ini sebagai <strong>Non Pers</strong></div>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.userData.pers = false;
          this.makeToast(
            "success",
            "Akun Telah Ditandai Sebagai Non Pers",
            5000
          );
        }
      });
    },
    turnOnNotification(id) {
      let idrows = id;
      this.userData = this.data.items.find((el) => el.id == idrows);
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Anda akan mendapatkan pemberitahuan ketika akun yang ditandai memposting konten yang mengandung kata kunci</div>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.userData.notification = true;
          this.makeToast("success", "Pemberitahuan Telah Dihidupkan", 5000);
        }
      });
    },
    turnOffNotification(id) {
      let idrows = id;
      this.userData = this.data.items.find((el) => el.id == idrows);
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Anda <strong>tidak</strong> akan mendapatkan pemberitahuan ketika akun ini memposting konten yang mengandung kata kunci</div>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.userData.notification = false;
          this.makeToast("success", "Pemberitahuan Telah Dimatikan", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
 