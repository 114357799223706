<template>
  <div>
    <!-- MEDIA SOSIAL -->
    <div class="row">
      <div class="col-md-12">
        <cardSourceMedsos />
      </div>
    </div>

    <!-- MEDIA KONVENSIONAL -->
    <div class="row">
      <div class="col-md-12">
        <cardSourceMedkon />
      </div>
    </div>
  </div>
</template>
<script>
import cardSourceMedsos from "@/components/source/cardSourceMedsos.vue";
import cardSourceMedkon from "@/components/source/cardSourceMedkon.vue";

export default {
  name: "Source",
  components: {
    cardSourceMedkon,
    cardSourceMedsos,
  },

  data() {
    return {
      title: "Source | Online Media Monitoring",
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>
