<template>
  <div>
    <b-card no-body class="h-100">
      <b-card-header>
        <b-card-title>Media Sosial</b-card-title>
        <!-- FILTER BUTTON -->
        <b-button
          variant="outline-primary"
          v-b-modal.filter_medsos
          class="ml-auto mr-1"
          style="width: 10%"
        >
          <i class="bi bi-funnel-fill mr-50"></i>
          <span class="align-middle">Filter</span>
        </b-button>

        <!-- FILTER MODAL -->
        <b-modal
          id="filter_medsos"
          ok-title="Cari"
          ok-variant="info"
          cancel-title="Batal"
          cancel-variant="danger"
          @ok="handleFilter"
        >
          <!-- CUSTOM MODAL TITLE -->
          <template #modal-title>
            <h4 class="row" style="margin-left: 1px"><b>Filter Data</b></h4>
            <small class="row" style="margin-left: 1px">
              Anda dapat melakukan filter data disini
            </small>
          </template>
          <b-card-text>
            <div class="row mt-2">
              <!-- FILTER BY JENIS AKUN -->
              <div class="col-md-6">
                Berdasarkan Jenis akun
                <hr />
                <!-- VIEW ALL -->
                <b-form-radio
                  v-model="pers"
                  selected="true"
                  value="true false"
                  class="custom-control-info"
                >
                  Tampilkan Semua
                </b-form-radio>

                <!-- PERS ACCOUNT -->
                <b-form-radio
                  v-model="pers"
                  value="true"
                  class="custom-control-info"
                >
                  Hanya Akun Pers
                </b-form-radio>

                <!-- NON PERS ACCOUNT -->
                <b-form-radio
                  v-model="pers"
                  value="false"
                  class="custom-control-info"
                >
                  Hanya Akun Non Pers
                </b-form-radio>
              </div>

              <!-- FILTER BY NOTIFICATION -->
              <div class="col-md-6">
                Berdasarkan Pemberitahuan
                <hr />
                <!-- VIEW ALL -->
                <b-form-radio
                  v-model="notif"
                  value="true false"
                  class="custom-control-info"
                >
                  Tampilkan Semua
                </b-form-radio>

                <!-- NOTIFICATION IS ACTIVE -->
                <b-form-radio
                  v-model="notif"
                  value="true"
                  class="custom-control-info"
                >
                  Pemberitahuan Dihidupkan
                </b-form-radio>

                <!-- NOTIFICATION IS NOT ACTIVE -->
                <b-form-radio
                  v-model="notif"
                  value="false"
                  class="custom-control-info"
                >
                  Pemberitahuan Dimatikan
                </b-form-radio>
              </div>
            </div>

            <!-- FILTER BY SOURCE -->
            <div class="row mt-2">
              <div class="col-md-12">
                Filter Berdasarkan Source
                <hr />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-3">
                <!-- FACEBOOK -->
                <b-form-checkbox
                  v-model="source"
                  value="facebook"
                  class="custom-control-info"
                >
                  Facebook
                </b-form-checkbox>

                <!-- YOUTUBE -->
                <b-form-checkbox
                  v-model="source"
                  value="youtube"
                  class="custom-control-info"
                >
                  Youtube
                </b-form-checkbox>
              </div>
              <div class="col-md-3">
                <!-- TWITTER -->
                <b-form-checkbox
                  v-model="source"
                  value="twitter"
                  class="custom-control-info"
                >
                  Twitter
                </b-form-checkbox>

                <!-- LINKEDIN -->
                <b-form-checkbox
                  v-model="source"
                  value="linkedin"
                  class="custom-control-info"
                >
                  Linkedin
                </b-form-checkbox>
              </div>
              <div class="col-md-3">
                <!-- INSTAGRAM -->
                <b-form-checkbox
                  v-model="source"
                  value="instagram"
                  class="custom-control-info"
                >
                  Instagram
                </b-form-checkbox>

                <!-- TIKTOK -->
                <b-form-checkbox
                  v-model="source"
                  value="tiktok"
                  class="custom-control-info"
                >
                  Tiktok
                </b-form-checkbox>
              </div>
              <div class="col-md-3">
                <!-- FORUM -->
                <b-form-checkbox
                  v-model="source"
                  value="forum"
                  class="custom-control-info"
                >
                  Forum
                </b-form-checkbox>

                <!-- BLOG -->
                <b-form-checkbox
                  v-model="source"
                  value="blog"
                  class="custom-control-info"
                >
                  Blog
                </b-form-checkbox>
              </div>
            </div>
          </b-card-text>
        </b-modal>
        <!-- SEARCH FORM -->
        <b-input-group class="input-group-merge w-25">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-primary" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Masukan Nama Akun dan Tekan Enter"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </b-card-header>
      <b-card-text class="ml-2 mr-auto mb-3" style="margin-top: -20px"
        >Menampilkan daftar akun yang memiliki posting sesuai dengan kata kunci
        yang dimonitor.
      </b-card-text>
      <!-- TABLE COMPONENT -->
      <sourceTable :data="table_data" class="mb-2" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BModal,
  VBModal,
  BFormRadio,
  BFormCheckbox,
} from "bootstrap-vue";
import sourceTable from "./sourceTable.vue";

export default {
  name: "cardSourceMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BModal,
    VBModal,
    BFormRadio,
    BFormCheckbox,
    sourceTable,
  },

  data() {
    return {
      pers: null,
      notif: null,
      source: [],
      table_data: {
        type: "source",
        row: 5,
        filter: null,
        fields: [
          {
            key: "no",
            label: "No",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5%" },
          },
          {
            key: "platform",
            label: "Platform",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10%" },
          },
          {
            key: "name",
            label: "Name",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "mention",
            label: "Mention",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10%", paddingRight: "2rem" },
          },
          {
            key: "reach",
            label: "Reach",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10%", paddingRight: "2rem" },
          },
          {
            key: "engagement",
            label: "Engagement",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10%", paddingRight: "2rem" },
          },
          {
            key: "follower",
            label: "Follower",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10%", paddingRight: "2rem" },
          },
          {
            key: "action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [],
      },
      items: [
        {
          id: 1,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "twitter",
          name: "Myra Foster",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: true,
        },
        {
          id: 2,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "facebook",
          name: "Jhon Chena",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: false,
        },
        {
          id: 3,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "instagram",
          name: "Alexandre Christie",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: false,
          notification: false,
        },
        {
          id: 4,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "youtube",
          name: "Wulan Sri",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: false,
          notification: true,
        },
        {
          id: 5,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "linkedin",
          name: "Kevin Sanjaya",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: false,
        },
        {
          id: 6,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "tiktok",
          name: "Juliansyah",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: false,
          notification: false,
        },
        {
          id: 7,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "blog",
          name: "Hotman Pasutrio",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: true,
        },
        {
          id: 8,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "forum",
          name: "Andre Kemala",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: true,
        },
        {
          id: 9,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "facebook",
          name: "Brock Lesnar",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: true,
        },
        {
          id: 10,
          avatar: require("@/assets/images/avatars/10-small.png"),
          platform: "twitter",
          name: "John Due",
          mention: 1001,
          reach: 1000,
          engagement: 1000,
          follower: 1000,
          pers: true,
          notification: true,
        },
      ],
    };
  },

  mounted() {
    this.table_data.items = this.items;
  },

  methods: {
    handleFilter() {
      if (this.filterValue == "") {
        return (this.table_data.items = []);
      } else {
        return (this.table_data.items = this.filterValue);
      }
    },
  },

  computed: {
    filterValue: function () {
      if (this.source == "") {
        if (this.pers == null) {
          if (this.notif == null) {
            return this.items;
          } else {
            return this.items.filter((el) =>
              this.notif.includes(el.notification.toString())
            );
          }
        } else {
          if (this.notif == null) {
            return this.items.filter((el) =>
              this.pers.includes(el.pers.toString())
            );
          } else {
            return this.items
              .filter((el) => this.pers.includes(el.pers.toString()))
              .filter((el) => this.notif.includes(el.notification.toString()));
          }
        }
      } else {
        if (this.pers == null) {
          if (this.notif == null) {
            return this.items.filter((el) =>
              this.source.includes(el.platform.toString())
            );
          } else {
            return this.items
              .filter((el) => this.source.includes(el.platform.toString()))
              .filter((el) => this.notif.includes(el.notification.toString()));
          }
        } else {
          if (this.notif == null) {
            return this.items
              .filter((el) => this.source.includes(el.platform.toString()))
              .filter((el) => this.pers.includes(el.pers.toString()));
          } else {
            return this.items
              .filter((el) => this.source.includes(el.platform.toString()))
              .filter((el) => this.pers.includes(el.pers.toString()))
              .filter((el) => this.notif.includes(el.notification.toString()));
          }
        }
      }
    },
  },
};
</script>
